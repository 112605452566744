import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';
import '../../HomePage.css';

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      <h1>Tinnitusbehandeling online</h1>
      <p>Ons doel...</p>
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Cursus</h3>
            <h5>145.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          <button className='button' type='submit'>
            Checkout
          </button>
        </form>
      </section>
    </div>
  );
};

export default HomePage;



