import { Outlet, NavLink } from "react-router-dom";

export default function TrainingLayout() {
    return (
      <div className="training-layout">
        
            <h2>Tinnitus training</h2>
            <p>Onze tinnitusbehandeling online bestaat uit 3 onderdelen, te weten <br></br><br></br>*een webinar waarin wordt uitgelegd hoe tinnitus kan 
            ontstaan en waarin uitgelegd wordt hoe we
              de anatomische neurologische route middels therapie beinvloeden en welke de basis vormt voor onze breintraining <br></br> <br></br>
              *een online assessment; het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
              waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
              korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 40 min, een om de problemen en mogelijkheden 
              in kaart te brengen en een om te evalueren en waar nodig bij te stellen. Het online consult gaat via de applicatie "Zoom". Er kan ook een inschatting worden gemaakt of de cursus geindiceerd is.
              <br></br><br></br>
              *de cursus die bestaat uit 10 weken, welke een hybride vorm heeft en een continue online begeleiding met interactieve werkvormen met de groep </p>

            <nav>
        <NavLink to="ebook">E book</NavLink>
        <NavLink to="onlineassessment">Online assessment</NavLink>
        <NavLink to="cursus">Cursus</NavLink>
      </nav>

            <Outlet />

            </div>
    )
}