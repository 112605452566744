import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';



const Onlineassessment = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      
      <div class="container">
        
        <b><p>Online assessment</p></b>
        <p>In het online assessment zal een gespecialiseerde therapeut ...</p>

    </div>

    
    
      
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Online assessment</h3>
            <h5>99.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          <button className='button' type='submit'>
            Bestel
          </button>
        </form>
      </section>
    </div>
  );
};

export default Onlineassessment;



