

import { Outlet, NavLink } from "react-router-dom";


export default function Orofaciaallayout() {
    return (
      <div className="orofaciaal-layout">
        
            <h2>Orofaciale therapie</h2>
            <p>Met Orofaciale therapie, ook wel kaakfysiotherapie genoemd, behandelen we somatosensorische tinnitus. De tinnitus is dan
              fysiek te beinvloeden: het is moduleerbaar via hands on technieken direct op het hoofd en/of op de kaak. Dit is zeer gunstig: de prognose is zeer goed. De kaakfysiotherapeut 
              werkt vaak samen met de tandarts, vooral als er sprake is van spierspanningen
              van de kauwspieren. Dit opbouwen van spierspanningen vindt plaats als er geklemd en/of geknarsd wordt. Dit kaakklemmen kan behandeld worden met een 
              opbeetplaat in opdracht van de tandarts; en met bewustwording en
              oefeningen middels kaakfysiotherapie. <br></br><br></br>

              Ook kan de orofaciaal therapeut met zogenaamde Crafta technieken de klachten behandelen door zachte technieken op de schedeldelen toe te passen.
              <br></br><br></br>
              Tenslotte is de betrokken hersenzenuw: de nervus vestibulocochlearis betrokken bij tinnitus. Deze zenuw stuurt het geluid aan, maar ook het evenwicht. 
              Met vestibulaire training ofwel evenwichtstraining trainen we de balans en coordinatie, welke een gunstig resultaat gaat hebben op de tinnitus.

            </p>

            <nav>
        <NavLink to="kaakfysiotherapie">Kaakfysiotherapie</NavLink>
        <NavLink to="CRAFTA">CRAFTA</NavLink>
        <NavLink to="Vestibulairetraining">Vestibulaire training</NavLink>
      </nav>
      

     
        <Outlet />
      </div>
    
    )
}

