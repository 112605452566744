import React from 'react';

const Kaakfysio = () => {
  return (
    <div>
      <h1>Cursus</h1>
      <p>Learn more about us on this page.</p>
    </div>
  );
};

export default Kaakfysio;