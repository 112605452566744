import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';



const Ebook = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      
      <div class="container">
        <b><p>Ebook</p></b>
        <p>In dit interactieve Ebook wordt je meegenomen in de anatomie van tinnitus. Je leert hoe spierspanning, houding, attitude, stress en gedrag invloed 
          hebben op tinnitus. Zowel op het ontstaan, als op het onderhouden van tinnitus. In het boek wordt duidelijk hoe somatosensorische tinnitus
          kan ontstaan en welke invloed de kaak-, en nekspanning op tinnitus kan hebben. 
          Het boek is interactief: ze beschrijft bovenstaande problematiek en geeft concrete en toepasbare oplossingen, maar het boek
           geeft ook toegang tot 3 kennisclips, die je in je eigen
          tijd kunt bekijken. Het vormt de basis voor de 10 weekse cursus.

</p>

          
        <div className="app-picture">
      <img alt="tinnitus" src="../../assets/tinnitus.png"/>
        
    </div>
    </div>
    

    
    
      
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Ebook</h3>
            <h5>20.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          <button className='button' type='submit'>
            Bestel
          </button>
        </form>
      </section>
    </div>
  );
};

export default Ebook;


