// src/components/layouts/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';


const MainLayout = () => {
  return (
    <div>
      
      <div className="content">
      <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;

