import React from 'react';

const CraftaPage = () => {
  return (
    <div>
      <h2>Crafta Page</h2>
      <p>Welcome to the home page!</p>
    </div>
  );
};

export default CraftaPage;